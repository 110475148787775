<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card title="Driver Performances">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="thead">
            <vs-th sort-key="name">Driver</vs-th>
            <vs-th sort-key="total_route">Total Route</vs-th>
            <vs-th sort-key="total_shipment">Total Shipment</vs-th>
            <vs-th sort-key="total_point">Total Point</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.name">{{tr.name}}</vs-td>
              <vs-td label="Total Route" class="text-right" :data="tr.total_route">{{tr.total_route}}</vs-td>
              <vs-td label="Total Shipment" class="text-right" :data="tr.total_shipment">{{tr.total_shipment}}</vs-td>
              <vs-td label="Total Point" class="text-right" :data="tr.total_point">{{tr.total_point}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  data(){
    return {
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.driver_performances.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'driver_performances/index'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      await this.dispatchIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>